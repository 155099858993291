import {FC} from "react"
import {useRouter} from "next/router"
import NextLink from "next/link"
import {Link as UiLink} from "@nextui-org/react"

const links = [
  {href: "/", title: "Dashboard"},
  {href: "/editor", title: "Editor"},
  {href: "/api/webhook/slack/install", title: "Add to Slack"},
  {href: "/api/signin/slack", title: "Sign-in with Slack"}
]

interface LinkProps {
  href: string
  children: string
  isActive: boolean
}

const Link: FC<LinkProps> = ({href, children, isActive}) => {
  return (
    <NextLink {...{href}}>
      <UiLink color={isActive ? "text" : "default"}>{children}</UiLink>
    </NextLink>
  )
}

const Nav: FC = () => {
  const {pathname} = useRouter()

  return (
    <ul className="flex space-x-2">
      {links.map(link => (
        <Link key={link.href} href={link.href} isActive={pathname === link.href}>
          {link.title}
        </Link>
      ))}
    </ul>
  )
}

export default Nav
