import {FC} from "react"
import {Loading} from "@nextui-org/react"
import NextLink from "next/link"

import {withLayout} from "components/Layout"
import useUser from "hooks/useUser"
import useSlackAppLink from "hooks/useSlackAppLink"
import SlackButton from "components/SlackButton"

const Button: FC = () => {
  const {link: goToSlackLink, isLoading} = useSlackAppLink()

  if (isLoading) {
    return <Loading />
  }

  if (goToSlackLink) {
    return <SlackButton href={goToSlackLink}>Go to Slack</SlackButton>
  }

  return <SlackButton href="/api/signin/slack">Sign in with Slack</SlackButton>
}

const Index: FC = () => {
  const {data: user} = useUser()
  const isEditor = user?.isEditor

  return (
    <div className="w-full h-screen flex flex-col">
      {isEditor && (
        <div className="w-full flex flex-row-reverse pt-2">
          <NextLink href="/editor">
            <a className="text-black font-serif hover:text-zinc-800 text-xl hover:underline underline">
              Editor.
            </a>
          </NextLink>
        </div>
      )}
      <div className="flex items-center justify-center flex-grow flex-col">
        <h1 className="font-serif text-7xl mb-8 font-normal">Debrief.</h1>
        <Button />
      </div>
    </div>
  )
}

export default withLayout(Index)
