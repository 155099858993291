import Sentry from "@gooditworks/monitoring/next"

import {useQuery} from "datasources/trpc"
import {useEffect} from "react"

const useUser = () => {
  const query = useQuery(["user.me"])

  useEffect(() => {
    const user = query.data?.user

    if (user) {
      Sentry.setUser({
        id: user.id.toString(),
        username: user.profile.username || undefined,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName
      })
    }
  }, [query.data])

  return query
}

export default useUser
