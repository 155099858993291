import {useMemo} from "react"

import {useQuery} from "datasources/trpc"

const useSlackAppLink = () => {
  const query = useQuery(["slack.installation"])

  const link = useMemo(() => {
    if (!query.data) {
      return null
    }

    const {appId, teamId} = query.data
    if (!appId || !teamId) {
      return null
    }

    return `https://slack.com/app_redirect?app=${appId}&team=${teamId}`
  }, [query.data])

  return {link, isLoading: query.isLoading}
}

export default useSlackAppLink
